import React, { useCallback, useState } from 'react';
import Link from '../components/Link';
import Page from '../components/Page';
import { useApp } from '../lib/oc-admin-hooks';
import { delayAsync } from '../lib/oc-admin-lib';

export default function PageMgrScreen()
{

    const {api}=useApp();
    const [status,setStatus]=useState('');

    const clearCache=useCallback(async ()=>{
        setStatus('Clearing...');
        await api.getAsync('Page/ClearCache');
        setStatus('Cleared');
        await delayAsync(1000);
        setStatus('')
    },[api])

    return (
        <Page>
            <h1>Page Manager</h1>

            <button onClick={clearCache} disabled={status?true:false}>{status||'Clear Pages Cache'}</button>

            <ul>
                <li><Link to="/db/Page">Pages</Link></li>
                <li><Link to="/db/PageSection">Page Sections</Link></li>
                <li><Link to="/db/PageSectionItem">Page Items</Link></li>
            </ul>
        </Page>
    )

}
