import './DragDrop.scss';
import React, { useCallback, useState, DragEvent } from 'react';
import { classes } from '../../lib/oc-admin-lib';

interface DragDropProps
{
    className?:string;
    children?:any;
    onFiles?:(files:File[])=>void;
}

export default function DragDrop({
    className,
    children,
    onFiles
}:DragDropProps){

    const [over,setOver]=useState(false);

    const onDragEnter=useCallback((e:DragEvent<HTMLDivElement>)=>{
        e.preventDefault();
        e.stopPropagation();
        setOver(true);
    },[]);

    const onDragExit=useCallback((e:DragEvent<HTMLDivElement>)=>{
        e.preventDefault();
        e.stopPropagation();
        setOver(false);
    },[]);

    const onDrop=useCallback((e:DragEvent<HTMLDivElement>)=>{
        e.preventDefault();
        e.stopPropagation();
        setOver(false);

        const files=e.nativeEvent.dataTransfer?.files;
        if(!files){
            return;
        }

        const list:File[]=[];
        for(let i=0;i<files.length;i++){
            const f=files.item(i);
            if(f){
                list.push(f);
            }
        }

        if(list.length>0){
            onFiles?.(list);
        }

    },[onFiles]);

    return (
        <div
            className={classes('drag-drop-area',over&&'drag-drop-area-over',className)}
            onDragEnter={onDragEnter}
            onDragOver={onDragEnter}
            onDragExit={onDragExit}
            onDrop={onDrop}>
            {children}
        </div>
    )

}
