import React, { FormEvent, useCallback, useState } from 'react';
import Page from '../components/Page';
import { SignInTypes } from '../lib/AuthTypes';
import { useApp } from '../lib/oc-admin-hooks';

export default function SignInScreen()
{

    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');

    const {auth}=useApp();

    const onSubmit=useCallback(async (e:FormEvent)=>{
        e.preventDefault();
        if(!email || !password){
            return;
        }

        const r=await auth.signInAsync({
            Type:SignInTypes.EmailPassword,
            Identity:email,
            Token:password
        });

        if(!r.signIn){
            setEmail('');
            setPassword('');
        }

    },[email,password,auth]);

    return (
        <Page>
            <h1>Sign-In</h1>

            <form onSubmit={onSubmit}>
                <div className="col">

                    <input
                        className="mb1"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={e=>setEmail(e.target.value)} />

                    <input
                        className="mb1"
                        type="password"
                        value={password}
                        placeholder="Password"
                        onChange={e=>setPassword(e.target.value)} />

                    <button disabled={!email || !password} type="submit">Sign-in</button>
                </div>
            </form>
        </Page>
    )

}
