import React from 'react';
import Link from '../../components/Link';
import { getPluralName, ReflectProvider } from '../lib/reflect-admin';

interface AdminDashboardProps
{
    provider:ReflectProvider;
}

export default function AdminDashboard({
    provider
}:AdminDashboardProps)
{

    return (
        <div className="admin-screen">
            <h3 className="admin-title">Admin Dashboard</h3>
            <ul className="admin-ul">
                {provider.ctx?.Types.map(t=>(
                    <li key={t.FullName}>
                        <Link to={`/db/${t.Name}`}>{getPluralName(t.Name)}</Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}
