import React, { useMemo } from 'react';
import { getDisplayValueForProp, getPluralName, ReflectProvider } from '../lib/reflect-admin';
import { RType } from '../lib/reflect-type';
import Link from '../../components/Link';
import AdminTrail from './AdminTrail';
import AdminLoading from './AdminLoading';
import { useAsync, useWindowSize } from '../../lib/oc-admin-hooks';

interface AdminListProps
{
    provider:ReflectProvider;
    type:RType;
    maxCols?:number;
    colWidth?:number;
}

export default function AdminList({
    type,
    provider,
    maxCols,
    colWidth=160
}:AdminListProps){

    const result=useAsync(
        null,
        ()=>provider.getList(type,0,30),
        'Unable to load list',
        [provider,type]
    )

    const {width}=useWindowSize();
    if(maxCols===undefined){
        maxCols=Math.ceil(width/colWidth);
    }

    const table=useMemo(()=>{
        if(!result){
            return null;
        }
        const cells:any[]=[];
        let cols=0;

        cells.push(<div key="id-header" className="admin-grid-header">Id</div>);
        cols++;
        for(const p of type.Props){
            if(p.Name==='Id' || p.IsRef || p.IsCollectionRef){
                continue;
            }
            cells.push(<div className="admin-grid-header" key={'header-'+p.Name}>{p.Name}</div>);
            cols++;
            if(cols===maxCols){
                break;
            }
        }
        cells.push(<div className="admin-grid-header" key={'header--tools'}/>);

        let colLayout='min-content';
        for(let h=0;h<cols-1;h++){
            colLayout+=' auto';
        }
        colLayout+=' min-content';

        for(const item of result.items){
            cells.push(<div key={'id-col:'+item.Id}>{item.Id}</div>);
            cols=1;

            for(const p of type.Props){
                if(p.Name==='Id' || p.IsRef || p.IsCollectionRef){
                    continue;
                }
                const value=item[p.Name];
                const center=value===true || value===false;
                const config=provider.getPropConfig(p,type);
                const display=provider.ctx?getDisplayValueForProp(p,provider.ctx,item,config?.listMaxLength||30):item[p.Name];

                cells.push(<div key={item.Id+':'+p.Name} className={center?'center':''}>{display}</div>);
                cols++;
                if(cols===maxCols){
                    break;
                }
            }
            cells.push(<div key={item.Id+':-tools'}>
                <Link to={`/db/${type.Name}/${item.Id}`}>Edit</Link>
            </div>);
        }
        return {cells, collLayout: colLayout};
    },[result,type,maxCols,provider]);

    const toolbar=(
        <div className="admin-toolbar admin-toolbar-container">
            <h3 className="admin-title">{getPluralName(type.Name)}</h3>
            <div>
                <Link to={`/db/${type.Name}/create`}>Add +</Link>
            </div>
        </div>
    )

    return (
        <div className="admin-screen admin-screen-list">

            <AdminTrail container dashboard />

            {toolbar}
            <div className="admin-grid admin-grid-list" style={{gridTemplateColumns:table?.collLayout}}>

                {table?.cells}

            </div>
            {!result&&<AdminLoading/>}
            {(result?.items?.length||0)>10 && toolbar}
        </div>
    )

}
