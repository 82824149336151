import React from 'react';
import Page from '../components/Page';
import Uploader from '../components/Upload/Uploader';

export default function UploadScreen()
{

    return (
        <Page>
            <h1>Uploads</h1>
            <Uploader />
        </Page>
    )

}
