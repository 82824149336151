import './Uploader.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useApp, useMounted } from '../../lib/oc-admin-hooks';
import { Upload } from '../../lib/oc-admin-types';
import DragDrop from './DragDrop';
import UploadItem from './UploadItem';
import UserSelector from '../UserSelector';
import { PublicProfileInfo } from '../../lib/OcTypes';
import Log from '../../lib/Log';
import { classes } from '../../lib/oc-admin-lib';
import { ProgressCallback } from '../../lib/OcAdminMediaManager';

interface UploadRef
{
    id:string;
    name:string;
    user:PublicProfileInfo;
}

export default function Uploader(){

    const mt=useMounted();

    const {media}=useApp();

    const [selectedUser,setSelectedUser]=useState<PublicProfileInfo|null>(null);

    const [files,setFiles]=useState<Upload[]>([]);
    const [uploaded,setUploaded]=useState<UploadRef[]>([]);

    const [isUploading,setIsUploading]=useState(false);

    useEffect(()=>{
        if(!selectedUser){
            return;
        }
        for(const file of files){
            file.userId=selectedUser.Id as number;
        }
    },[selectedUser,files]);

    const [busyFile,setBusyFile]=useState('');

    const onFiles=useCallback(async (files:File[])=>{
        if(isUploading){
            return;
        }
        for(const file of files){
            if(file.type!=='video/mp4'){
                continue;
            }
            setBusyFile(file.name);
            const upload=await media.createUploadAsync(file);
            if(!mt.current){
                return;
            }
            setFiles(f=>[...f,upload]);
        }
        setBusyFile('');
        
    },[media,mt,isUploading]);

    const [progress,setProgress]=useState({
        fileCount:0,
        index:0,
        size:0,
        sent:0,
        current:null as Upload|null
    });

    const uploadAll=useCallback(async ()=>{

        if(!selectedUser){
            return;
        }

        setIsUploading(true);

        try{
            let uploadIndex=0;
            const progress:ProgressCallback=(u,sent,size)=>{
                if(mt.current){
                    setProgress({
                        fileCount:files.length,
                        index:uploadIndex,
                        sent,size,
                        current:u
                    })
                }
            }
            for(let i=files.length-1;i>-1;i--){
                await media.uploadAsync(files[i],progress);
                uploadIndex++;
                if(!mt.current){
                    return;
                }
                setUploaded(u=>[...u,{id:files[i].id,name:files[i].name,user:selectedUser}]);
            }
        }catch(ex){
            Log.error('Upload error',ex);
        }
        if(mt.current){
            setIsUploading(false);
            setProgress({
                fileCount:0,
                index:0,
                size:0,
                sent:0,
                current:null
            });
            setFiles([]);
        }

    },[mt,files,media,selectedUser]);

    const move=useCallback((upload:Upload,to:number)=>{
        setFiles(v=>{

            let i=v.indexOf(upload);
            if(i===-1){
                return v;
            }

            if(to<0){
                to=0;
            }else if(to>=v.length){
                to=v.length-1;
            }

            if(to===i){
                return v;
            }

            v=[...v];
            v.splice(i,1);
            v.splice(to,0,upload);

            return v;
        })
    },[]);

    return (
        <div className={classes("uploader",isUploading&&'uploading')}>

            <section className="mb5">
                <h4 className="mb0">User</h4>
                <p>Select a user to upload videos for.</p>
                <UserSelector disabled={isUploading} onSelected={setSelectedUser} />
                {selectedUser&&<div className="user-info">
                    {!!selectedUser.ProfilePicture&&<img alt={selectedUser.DisplayName} src={media.getCdnUrl(selectedUser.ProfilePicture) as string}/>}
                    {selectedUser.DisplayName}
                </div>}
            </section>

            {selectedUser&&<div className="uploader-content">
                
                <DragDrop onFiles={onFiles} className="mb3">
                    <div className="upload-area">
                        {busyFile?
                            <>Processing {busyFile}</>
                        :progress.current?
                            <>Uploading {progress.index+1} of {progress.fileCount} - {progress.current.name}</>
                        :
                            <>Drag &amp; Drop MP4s here</>
                        }
                    </div>
                </DragDrop>


                {!!files.length&&
                    <section className="mt5">
                        <div className="row space-between center-items">
                            <h4>Upload Queue</h4>
                            {!isUploading && <button onClick={uploadAll} className="primary">Upload{files.length>1&&' All'}</button>}
                        </div>
                        {files.map((u,i)=>{

                            const isUp=uploaded.find(up=>up.id===u.id)?true:false;
                            const isCurrent=u===progress.current;
                        
                            return (
                                <UploadItem
                                    key={u.id}
                                    upload={u}
                                    move={move}
                                    index={i}
                                    locked={isUploading}
                                    sent={isUp?1:isCurrent?progress.sent:0}
                                    total={isUp?1:isCurrent?progress.size:0}/>
                            );
                        })}

                    </section>
                }
            </div>}

            {!!uploaded.length&&<>
                <h4>Uploaded</h4>
                <ul>
                    {uploaded.map(u=>(
                        <li key={u.id}>{u.name} &nbsp; ➡️ &nbsp; {u.user.DisplayName}</li>
                    ))}
                </ul>
            </>}

        </div>
    )

}
