import './UserSelector.scss';
import React, { useEffect, useState } from 'react';
import { useApp } from '../lib/oc-admin-hooks';
import { PublicProfileInfo } from '../lib/OcTypes';

interface UserSelectorProps
{
    onSelected?:(user:PublicProfileInfo)=>void;
    disabled?:boolean;
}

export default function UserSelector({
    onSelected,
    disabled
}:UserSelectorProps){

    const {api,media}=useApp();

    const [search,setSearch]=useState('');
    const [users,setUsers]=useState<PublicProfileInfo[]>([]);
    const [busy,setBusy]=useState(false);

    useEffect(()=>{
        setUsers([]);
        if(search.length<3){
            setBusy(false);
            return;
        }
        let m=true;
        setBusy(true);
        setTimeout(async ()=>{
            if(!m){
                return;
            }

            const users=await api.getAsync<PublicProfileInfo[]>('User/Search',{query:search});
            if(m){
                setBusy(false);
                setUsers(users);
            }

        },1000);
        return ()=>{m=false}
    },[search,api]);

    return (
        <div className="user-selector">
            <input placeholder="Search Users" disabled={disabled} value={search} onChange={e=>setSearch(e.target.value)}  />

            {!disabled&&<ul>
                {users.map(u=>(
                    <li key={u.Id}>
                        <button onClick={()=>{onSelected?.(u);setSearch('')}}>
                            {!!u.ProfilePicture&&<img alt={u.DisplayName} src={media.getCdnUrl(u.ProfilePicture) as string}/>}
                            {u.DisplayName}
                        </button>
                    </li>
                ))}
            </ul>}
            {busy&&<h4>⌛</h4>}
            {(search.length>3 && !users.length && !busy)&&<h4>No matches found</h4>}
        </div>
    )

}
