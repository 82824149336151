import React from 'react';
import { AdminRoutes } from '../admin/components/Admin';
import { Screen, ScreenGroup } from '../components/Screen';
import { useUser } from '../lib/oc-admin-hooks';
import DashboardScreen from './DashboardScreen';
import PageMgrScreen from './PageMgrScreen';
import SignInScreen from './SignInScreen';
import UploadScreen from './UploadScreen';


export default function Screens()
{

    const user=useUser();

    return (
        <ScreenGroup>

            {!user&&<Screen path="/" render={()=>(
                <SignInScreen/>
            )}/>}

            <Screen path="/" exact render={()=>(
                <DashboardScreen/>
            )}/>
            <Screen path="/upload" exact render={()=>(
                <UploadScreen/>
            )}/>
            <Screen path="/page-mgr" exact render={()=>(
                <PageMgrScreen/>
            )}/>
            <AdminRoutes />
        </ScreenGroup>
    )

}

