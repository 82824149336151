export interface RCtx {
    Types: RType[];

    Enums: REnum[];
}
export enum RDataType {
    Object = 0,
    String = 1,
    Int = 2,
    Float = 3,
    DateTime = 4,
    Bool = 5
}
export interface RProp {
    Name: string;

    Type: RDataType;

    IsKey: boolean;

    IsEnum: boolean;

    AutoGenerated: boolean;

    CanRead: boolean;

    CanWrite: boolean;

    IsNullable: boolean;

    MaxLength: number | null;

    IsRef: boolean;

    IsCollectionRef: boolean;

    RefType: string;

    RefName: string;

    IsRefId: boolean;

    TypeString: string;

    TypeFullName: string;
}

export interface RType {
    Name: string;

    FullName: string;

    Namespace: string;

    Props: RProp[];
}

export interface REnum {

    FullName: string;

    IsFlags: boolean;

    Values: REnumValue[];
}

export interface REnumValue {
    Name: string;

    Value: number;
}
