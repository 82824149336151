import './ProgressBar.scss';
import React from 'react';
import { classes } from '../lib/oc-admin-lib';

interface ProgressBarProps
{
    className?:string;
    current:number;
    total:number;
}

export default function ProgressBar({
    className,
    current,
    total
}:ProgressBarProps){

    return (
        <div className={classes("progress-bar",className)}>
            <div style={{width:Math.min(1,Math.max(0,current/(total||1)))*100+'%'}}/>
        </div>
    )

}
