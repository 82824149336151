import EventEmitter from "events";
import React, { DependencyList, MutableRefObject, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { SignIn } from "./AuthTypes";
import Log from "./Log";
import { aryRemoveItem } from "./oc-admin-lib";
import { AppInitialization, OcAdminConfig } from "./oc-admin-types";
import OcAdminApp from "./OcAdminApp";

export const OcAdminAppContext=React.createContext<OcAdminApp|null>(null);

export function useInitApp(config:OcAdminConfig)
{
    const [app,setApp]=useState<AppInitialization>({app:null,error:null});

    useEffect(()=>{

        let m=true;

        let inited=false;
        const app:OcAdminApp=new OcAdminApp(config);

        (async ()=>{
            try{
                await app.initAsync();
                inited=true;
                if(m){
                    setApp({
                        app,
                        error:null
                    });
                }
            }catch(ex){
                Log.error('App init failed',ex);
                app.dispose();
                if(m){
                    setApp({
                        app:null,
                        error:'App initialization failed',
                        errorObj:ex
                    });
                }
            }
        })();

        return ()=>{
            m=false;
            if(inited){
                app.dispose();
            }
        }

    },[config]);

    return app;
}

export function useApp():OcAdminApp
{
    return useContext(OcAdminAppContext) as OcAdminApp;
}

export function useAppAsync<T>(getAsync:(app:OcAdminApp)=>Promise<T>,errorMsg:string,deps:DependencyList,resetValueOnUpdate?:boolean):T|null
{
    const app=useApp();
    const cb=useCallback(getAsync,deps);// eslint-disable-line
    return useAsync<T,null>(null,()=>cb(app),errorMsg,deps,resetValueOnUpdate);
}

export function useUser():SignIn|null
{
    const {auth}=useApp();
    const user=useProperty(auth,'signIn');
    return user;
}




export function useCached<T>(value:T,timeout:number=0):T{
    const [v,setV]=useState<T>(value);

    useEffect(()=>{
        let iv:any=0;
        if(value){
            setV(value);
        }else if(timeout){
            iv=setTimeout(()=>{
                setV(value);
            },timeout);
        }
        return ()=>{
            if(iv){
                clearTimeout(iv);
            }
        }
    },[value,timeout]);

    return v;
}

type Mounted=MutableRefObject<boolean>

export function useMounted()
{
    const mt=useRef(true);
    useLayoutEffect(()=>{
        return ()=>{
            mt.current=false;
        }
    },[]);
    return mt;
}

export function useRender():[number,()=>void]
{
    const [r,setR]=useState(0);
    const cb=useCallback(()=>{
        setR(r=>r+1);
    },[]);
    return [r,cb];
}

export function useAsync<T,D>(defaultValue:D,asyncCallback:(mt:Mounted)=>Promise<T>,errorMessage:string,deps:DependencyList,resetValueOnUpdate?:boolean):T|D
{
    const [value,setValue]=useState<T|D>(defaultValue);
    const cb=useCallback(asyncCallback,deps);// eslint-disable-line
    const mt=useMounted();

    useEffect(()=>{
        if(resetValueOnUpdate){
            setValue(defaultValue);
        }
        let active=true;
        const doCall=async ()=>{
            try{
                const r=await cb(mt);
                if(active){
                    setValue(r);
                }
            }catch(ex){
                Log.error(errorMessage,ex);
            }
        }
        doCall();
        return ()=>{
            active=false;
        }
    },[cb,mt,errorMessage,resetValueOnUpdate,defaultValue])

    return value;
}

export interface LockInfo
{
    hasLock:boolean;
    hasLockAndDelay:boolean;
}
export interface LockInfoPrivate
{
    hasLock:boolean;
    setHasLock:(hasLock:boolean)=>void;
}
const lockMap:{[key:string]:LockInfoPrivate[]}={}
function updateLocks(locks:LockInfoPrivate[])
{
    for(let i=locks.length-1;i>-1;i--){
        const loc=locks[i];
        if(i===0){
            if(!loc.hasLock){
                loc.setHasLock(true);
            }
        }else{
            if(loc.hasLock){
                loc.setHasLock(false);
            }
        }
    }
}
export function useLock(name:string, active:boolean=true ,delay:number=0):LockInfo{

    const delayRef=useRef(delay);

    const [loc,setLoc]=useState<LockInfo>({hasLock:false,hasLockAndDelay:false});


    useEffect(()=>{
        if(!name || !active){
            setLoc({hasLock:false,hasLockAndDelay:false});
            return;
        }
        let m=true;
        let locks=lockMap[name];
        if(!locks){
            locks=[];
            lockMap[name]=[];
        }
        let setId=0;
        const loc:LockInfoPrivate={
            hasLock:false,
            setHasLock:(hasLock:boolean)=>{
                const sid=++setId;
                if(m){
                    setLoc({hasLock,hasLockAndDelay:hasLock && delayRef.current===0});
                    if(hasLock && delayRef.current){
                        setTimeout(()=>{
                            if(sid===setId && m){
                                setLoc({hasLock,hasLockAndDelay:true});
                            }
                        },delayRef.current);
                    }
                }
            }
        }
        locks.push(loc);
        updateLocks(locks);

        return ()=>{
            m=false;
            aryRemoveItem(locks,loc);
            if(locks.length){
                updateLocks(locks);
            }else{
                delete lockMap[name];
            }
        };

    },[name,delayRef,active]);

    return loc;

}

export function useDelayValue<T>(currentValue:T,delayedValue:T,delayMs:number):T
{
    if(delayMs<1){
        delayMs=1;
    }

    const [value,setValue]=useState(currentValue);
    useEffect(()=>{
        let m=true;

        if(currentValue===delayedValue){
            setTimeout(()=>{
                if(m){
                    setValue(currentValue);
                }
            },delayMs)
        }else{
            setValue(currentValue);
        }

        return ()=>{m=false}
    },[currentValue,delayedValue,delayMs]);

    return value;
}

export function useDelayFalse(currentValue:boolean,delayMs:number){
    return useDelayValue(currentValue,false,delayMs);
}

export function useEvent(
    emitter:EventEmitter,
    event:string|symbol,
    listener: (...args: any[]) => void,
    enabled:boolean=true)
{
    useLayoutEffect(()=>{
        if(emitter && enabled){
            emitter.on(event,listener);
        }
        return ()=>{
            if(emitter && enabled){
                emitter.off(event,listener);
            }
        }
    },[emitter,listener,enabled,event]);

}

export function useUpdateEvent(
    emitter:EventEmitter,
    event:string|symbol,
    enabled:boolean=true):number
{
    const [index,setIndex]=useState<number>(0);

    const increment=useCallback(()=>{
        setIndex(v=>v+1);
    },[]);

    useEvent(emitter,event,increment,enabled);

    return index;

}

export function useProperty<T extends EventEmitter,K extends keyof T>(emitter:T,propertyName:K)
{
    useUpdateEvent(emitter,propertyName as string);
    return emitter[propertyName];
}


export interface WindowBreakpoints{
    sm:number
    md:number
    lg:number
    xl:number
}

export const defaultBreakPoints:WindowBreakpoints={
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
}

export const BreakpointsContext=React.createContext<WindowBreakpoints>(defaultBreakPoints);

export function useBreakpoints()
{
    return useContext(BreakpointsContext);
}

export enum WindowBreakpoint{
    xs=0,
    sm=1,
    md=2,
    lg=3,
    xl=4,
}

export interface WindowSize{
    width:number
    height:number
    breakpoint:WindowBreakpoint
    isMobile:boolean
    isTab:boolean
    stack:boolean
}

export function useWindowSize():WindowSize{

    const [width,setWidth]=useState<number>(window.innerWidth);
    const [height,setHeight]=useState<number>(window.innerHeight);
    const bp=useBreakpoints();
    const breakpoint=numberToBreakpoint(width,bp);
    const isMobile=breakpoint<=WindowBreakpoint.sm;
    const isTab=isMobile?false:breakpoint<=WindowBreakpoint.md;

    useEffect(()=>{
        const listener=()=>{
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize',listener);
        return ()=>{
            window.removeEventListener('resize',listener);
        }
    },[]);

    return {
        width,
        height,
        breakpoint,
        isMobile,
        isTab,
        stack:isMobile||isTab
    };

}

export function useBreakpointBodyClasses(logChangesToConsole?:boolean)
{
    const {breakpoint,isMobile,isTab}=useWindowSize();
    useLayoutEffect(()=>{
        if(logChangesToConsole){
            console.log('Set breakpoint class to '+WindowBreakpoint[breakpoint]);
        }
        const body=window.document.body;
        body.classList.remove('bp-xs','bp-sm','bp-md','bp-lg','bp-xl','bp-mobile','bp-tab','bp-desktop');
        body.classList.add('bp-'+WindowBreakpoint[breakpoint]);
        body.classList.add(isMobile?'bp-mobile':(isTab?'bp-tab':'bp-desktop'));
    },[breakpoint,isMobile,isTab,logChangesToConsole]);
}

export function numberToBreakpoint(num:number, breakpoints?:WindowBreakpoints):WindowBreakpoint{
    const bp=breakpoints||defaultBreakPoints;
    if(num<bp.sm){
        return WindowBreakpoint.xs;
    }else if(num<bp.md){
        return WindowBreakpoint.sm;
    }else if(num<bp.lg){
        return WindowBreakpoint.md;
    }else if(num<bp.xl){
        return WindowBreakpoint.lg;
    }else{
        return WindowBreakpoint.xl;
    }
}
