import './UploadItem.scss';
import React, { useEffect, useState } from 'react';
import { Upload } from '../../lib/oc-admin-types';
import ProgressBar from '../ProgressBar';

interface UploadItemProps
{
    upload:Upload;
    move:(upload:Upload,dir:number)=>void;
    locked:boolean;
    index:number;
    sent:number;
    total:number;
}

export default function UploadItem({
    upload,
    locked,
    index,
    move,
    sent,
    total
}:UploadItemProps){

    const [description,setDescription]=useState('');
    const [tags,setTags]=useState('');
    const [order,setOrder]=useState((index+1).toString());
    useEffect(()=>{
        setOrder((index+1).toString());
    },[index]);

    useEffect(()=>{
        upload.description=description;
    },[upload,description]);

    useEffect(()=>{
        upload.tags=tags;
    },[upload,tags]);

    

    return (
        <div className="upload-item">

            {!!total&&<ProgressBar current={sent} total={total} className="mt2" />}
            
            <div className="header-row">
                <h4>{upload.name}</h4>
                <input
                    disabled={locked}
                    value={order}
                    onChange={e=>{
                        if(!e.target.value){
                            setOrder('');
                            return;
                        }
                        if(!isNaN(Number(e.target.value))){
                            setOrder(e.target.value);
                        }
                    }}
                    onBlur={()=>move(upload,Number(order)-1)}
                    onKeyPress={e=>e.code.toLowerCase()==='enter'&&move(upload,Number(order)-1)}/>
            </div>

            <label>Description</label>
            <input disabled={locked} className="mb2" value={description} onChange={e=>setDescription(e.target.value)}/>

            <label>Tags</label>
            <input disabled={locked} className="mb2" value={tags} onChange={e=>setTags(e.target.value)}/>
            
            <div className="row">
                <div className="upload-container">
                    Preview
                    <video tabIndex={-1} src={upload.url} controls />
                </div>
                <div className="upload-container">
                    Thumbnail
                    <img src={upload.previewUrl} alt="preview" />
                </div>
            </div>
        </div>
    )

}
