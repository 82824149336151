import http, { AxiosResponse } from 'axios';
import Log, { LogLevel } from './Log';
import EventEmitter from "events";
import { trimStrings } from './oc-admin-lib';

export const httpUiRequest=Symbol();

export const httpErrorEvent=Symbol();

export interface HttpRequestConfig
{
    emitErrors?:boolean;
    authToken?:string;
    noAuth?:boolean;
}

export interface HttpError
{
    method:string;
    path:string;
    data:any;
    statusCode:number;
    message:string;
    error:Error;
}

export enum HttpUiRequestEventStatus
{
    Waiting = 0,
    Error = 1,
    Success = 2
}

export interface HttpUiRequestEvent
{
    id:number;
    description:any;
    error:Error|null;
    status:HttpUiRequestEventStatus;
}

export function getHttpErrorStatusCode(error:Error,fallbackCode?:number):number|undefined
{
    const ex:any=error;
    if(ex && ex.httpError && ex.httpError.StatusCode){
        return ex.httpError.StatusCode;
    }else{
        return fallbackCode;
    }
}

export function getHttpErrorMessage(error:Error,fallbackMessage?:string):string
{
    const ex:any=error;
    if(ex.httpError && ex.httpError.Message){
        return ex.httpError.Message;
    }else{
        return fallbackMessage||'Error';
    }
}

export function logHttpError(error:Error,fallbackMessage?:string,logLevel?:LogLevel):string
{
    if(logLevel===undefined){
        logLevel=LogLevel.error;
    }
    const msg=getHttpErrorMessage(error,fallbackMessage);
    Log.log({level:logLevel,message:msg,error});
    return msg;
}

export default class OcAdminApiManager extends EventEmitter
{
    private _baseUrl:string;
    private _authToken:string|null;

    public trimData:boolean;


    constructor(baseUrl:string,trimData:boolean=false)
    {
        super();
        this._baseUrl=baseUrl;
        this._authToken=null;
        this.trimData=trimData;
    }

    setBaseUrl(baseUrl:string)
    {
        this._baseUrl=baseUrl;
    }

    getBaseUrl()
    {
        return this._baseUrl;
    }

    getAsync<T>(path:string,data:any=null):Promise<T>
    {
        return this.callAsync('GET',path,data);
    }

    async getSingleAsync<T>(path:string,data:any=null):Promise<T>
    {
        const r=await this.callAsync<T[]>('GET',path,data);
        if(Array.isArray(r)){
            return r[0];
        }else{
            return r as any;
        }
    }

    postAsync<T>(path:string,data:any=null):Promise<T>
    {
        return this.callAsync('POST',path,data);
    }

    putAsync<T>(path:string,data:any=null):Promise<T>
    {
        return this.callAsync('PUT',path,data);
    }

    patchAsync<T>(path:string,data:any=null):Promise<T>
    {
        return this.callAsync('PATCH',path,data);
    }

    deleteAsync<T>(path:string,data:any=null):Promise<T>
    {
        return this.callAsync('DELETE',path,data);
    }

    postFormAsync<T>(path:string,formData:any):Promise<T>
    {
        return this.callAsync('POST',path,formData,(r:any)=>{
            r.headers['Content-Type']='multipart/form-data';
        });
    }

    async callAsync<T>(method:string,path:string,data:any,configRequest?:((request:any)=>void)|null,config?:HttpRequestConfig):Promise<T>
    {
        const oPath=path;
        const isRel=path.indexOf('http:')===-1 && path.indexOf('https:')===-1;
        if(isRel){
            path=this._baseUrl+path;
        }

        if(data && this.trimData){
            data=trimStrings(data);
        }

        const request:any={
            method:method,
            url:path,
            headers:{}
        };
        if(data){
            if(method==='GET' || method==='DELETE'){
                request.params=data;
            }else{
                request.data=data;
            }

        }
        if(!config?.noAuth){
            let authToken:string|null=null;
            if(config?.authToken){
                authToken=config.authToken;
            }else{
                authToken=this._authToken;
            }

            if(authToken && (isRel || config?.authToken)){
                if(method==='GET' || method==='DELETE'){
                    if(!request.params){
                        request.params={}
                    }
                    request.headers['Authorization']=authToken;
                }else{
                    request.headers['Authorization']=authToken;
                }
            }
        }

        if(configRequest){
            configRequest(request);
        }

        try{
            const result=await http(request);

            if(result.data && result.data['@odata.context']){
                if(Array.isArray(result.data.value)){
                    return result.data.value;
                }else{
                    delete result.data['@odata.context'];
                    return result.data;
                }
            }else{
                return result.data;
            }
        }catch(ex){

            if( ex.response &&
                ex.response.data &&
                ex.response.data.Message!==undefined &&
                ex.response.data.StatusCode!==undefined)
            {
                ex.httpError=ex.response.data;
            }

            if(config?.emitErrors!==false){
                const errorResponse:AxiosResponse<any>=ex.response;

                const httpError:HttpError={
                    path:oPath,
                    data,
                    method,
                    statusCode:errorResponse?.status||0,
                    message:getHttpErrorMessage(ex),
                    error:ex
                }

                this.emit(httpErrorEvent,httpError);
            }

            throw ex;

        }
    }

    setAuthToken(token:string|null)
    {
        this._authToken=token;
    }


    uploadFileAsync<T>(path:string,value:Blob,paramName:string='File',fileName?:string,appendData?:{[key:string]:any}):Promise<T>
    {
        const data = new FormData();
        if(appendData){
            for(const e in appendData){
                if(appendData[e]===undefined){
                    continue;
                }
                data.append(e,appendData[e]?.toString()||'')
            }
        }
        data.append(paramName,value,fileName);
        return this.callAsync('POST',path,data,(r:any)=>{
            r.headers['Content-Type']='multipart/form-data';
        });
    }


    uiGetAsync<T>(description:any,path:string,data:any=null):Promise<T|null>
    {
        return this.uiContext<T>(description,()=>(this.getAsync(path,data) as Promise<T>));
    }

    uiGetSingleAsync<T>(description:any,path:string,data:any=null):Promise<T|null>
    {
        return this.uiContext<T>(description,()=>(this.getSingleAsync(path,data) as Promise<T>));
    }

    uiPostAsync<T>(description:any,path:string,data:any=null):Promise<T|null>
    {
        return this.uiContext<T>(description,()=>(this.postAsync(path,data) as Promise<T>));
    }

    uiPutAsync<T>(description:any,path:string,data:any=null):Promise<T|null>
    {
        return this.uiContext<T>(description,()=>(this.putAsync(path,data) as Promise<T>));
    }

    uiDeleteAsync<T>(description:any,path:string,data:any=null):Promise<T|null>
    {
        return this.uiContext<T>(description,()=>(this.deleteAsync(path,data) as Promise<T>));
    }

    uiPostFormAsync<T>(description:any,path:string,formData:any):Promise<T|null>
    {
        return this.uiContext<T>(description,()=>(this.postFormAsync(path,formData) as Promise<T>));
    }

    private uiRequestId:number=0;

    private async uiContext<T>(description:any,request:()=>Promise<T>):Promise<T|null>
    {
        const id=this.uiRequestId++;
        const evt:HttpUiRequestEvent={
            id,
            description,
            error:null,
            status:HttpUiRequestEventStatus.Waiting
        };
        try{
            this.emit(httpUiRequest,evt);
            const result = await request();
            evt.status=HttpUiRequestEventStatus.Success;
            return result;
        }catch(ex){
            evt.error=ex;
            evt.status=HttpUiRequestEventStatus.Error;
            Log.error('http error',ex);
            return null;
        }finally{
            this.emit(httpUiRequest,evt);
        }
    }
}
