import { OcAdminConfig } from "./lib/oc-admin-types";

let isNGRok=false;
const isLocalHost=window.location.hostname==='localhost';
const is127=window.location.hostname==='127.0.0.1';
try{
    isNGRok=window.location.hostname.endsWith('.ngrok.io');
}catch{}
const isDev=isNGRok || isLocalHost || is127;

const ocAdminConfig:OcAdminConfig={
    ApiBaseUrl:isDev?'https://oc-api.ngrok.io/api/':'https://api-dev-v2.grbll.opencanvas.services/api/',
    IsDev:isDev
}

export default ocAdminConfig;