import React, { useCallback, useMemo, useState } from 'react';
import { getDisplayName, PropConfig, ReflectProvider, SubmitHandler } from '../lib/reflect-admin';
import { RDataType, RProp, RType } from '../lib/reflect-type';
import AdminLoading from './AdminLoading';
import Link from '../../components/Link';
import { useAsync } from '../../lib/oc-admin-hooks';


const autoInitProps:string[]=[
    'Guid',
    'Created',
    'UUID'
];

export interface InputProps
{
    provider:ReflectProvider;
    type:RType;
    prop:RProp;
    forCreate:boolean;
    data:any;
    onChange:(prop:RProp,newValue:any)=>void;
    beforeSubmit:SubmitHandler[];
    propConfig:PropConfig|null;
    disabled:boolean;
}



export function inputForProp(props:InputProps):React.ReactNode|null
{

    const {provider,prop,forCreate}=props;

    const ctx=provider.ctx;

    if(!ctx || (forCreate && ((prop.IsKey && prop.AutoGenerated) || autoInitProps.indexOf(prop.Name))!==-1)){
        return null;
    }

    if(!forCreate && prop.IsKey){
        return <input placeholder={prop.Name} disabled key={prop.Name} />
    }

    // if(prop.Name.endsWith("Path")){
    //     if(forCreate){
    //         return null;
    //     }else{
    //         return <FileInput key={prop.Name} {...props}/>
    //     }
    // }

    if(prop.IsRefId){
        return <RefSelect key={prop.Name} {...props}/>
    }

    if(prop.IsRef){
        return null;
    }

    if(prop.IsEnum){
        const en=ctx.Enums.find(e=>e.FullName===prop.TypeFullName);
        if(!en){
            return null;
        }
        return en.IsFlags?
            <EnumFlags key={prop.Name} {...props} />:
            <EnumSelect key={prop.Name} {...props} />;
    }

    const valueType=prop.IsEnum?RDataType.Int:prop.Type;

    switch(valueType){

        case RDataType.Int:
        case RDataType.Float:
            return <TextInput key={prop.Name} {...props} />

        case RDataType.Bool:
            return <Checkbox key={prop.Name} {...props} />

        case RDataType.DateTime:
            return <TextInput key={prop.Name} {...props} />

        default:
            return <TextInput key={prop.Name} {...props} />

    }
}


function TextInput({
    data,
    prop,
    disabled,
    onChange,
}:InputProps)
{

    const isNumber=
        prop.Type===RDataType.Int ||
        prop.Type===RDataType.Float ||
        prop.IsEnum;

    const [value,setValue]=useState(data[prop.Name]);
    const strValue=(value===undefined||value===null)?'':value.toString();

    const _onChange=useCallback((v:string)=>{
        const value=isNumber?Number(v):v;
        onChange(prop,value);
        setValue(data[prop.Name])
    },[prop,isNumber,onChange,data])


    if(prop.Type===RDataType.String && (prop.MaxLength===null || prop.MaxLength>300)){
        return (
            <textarea
                disabled={disabled}
                className="admin-textarea"
                placeholder={prop.Name}
                value={strValue}
                maxLength={prop.MaxLength||undefined}
                onChange={e=>_onChange(e.target.value)}/>
        )
    }else{
        return (
            <input
                disabled={disabled}
                type={isNumber?'number':'text'}
                className="admin-input"
                placeholder={prop.Name}
                value={strValue}
                maxLength={prop.MaxLength||undefined}
                onChange={e=>_onChange(e.target.value)}/>
        )
    }
}

function Checkbox({
    data,
    prop,
    disabled,
    onChange

}:InputProps)
{
    const [value,setValue]=useState<boolean>(data[prop.Name]?true:false);

    const _onChange=useCallback((v:boolean)=>{
        onChange(prop,v);
        setValue(data[prop.Name]?true:false)
    },[prop,onChange,data])

    return (
        <input
            type="checkbox"
            className="admin-checkbox"
            checked={value}
            disabled={disabled}
            onChange={e=>_onChange(e.target.checked)}/>
    )
}

function RefSelect({
    provider,
    data,
    prop,
    onChange,
    forCreate

}:InputProps)
{
    const [value,setValue]=useState(data[prop.Name]);
    const strValue=(value===undefined||value===null)?'':value.toString();

    const refType=provider.ctx?.Types.find(t=>t.FullName===prop.RefType);
    const options=useAsync(
        null,
        async ()=>refType?await provider.getList(refType,0,0):null,
        'Unable to load options',
        [provider,prop]
    )

    const _onChange=useCallback((v:string)=>{
        onChange(prop,v?Number(v):null);
        setValue(data[prop.Name])
    },[prop,onChange,data])


    if(!options){
        return <AdminLoading slim/>
    }

    return (
        <div className="admin-select-container">
            <select className="admin-select" value={strValue} onChange={e=>_onChange(e.target.value)}>
                {(prop.IsNullable||forCreate)&&<option value="">(none)</option>}
                {options.items.map(o=>(
                    <option key={o.Id} value={o.Id.toString()}>{getDisplayName(o,refType)}</option>
                ))}
            </select>
            {refType&&!!strValue&&<Link to={`/db/${refType.Name}/${strValue}`}>View</Link>}
        </div>
    )
}

// function getStoragePath(name:string|null|undefined, type:RType, prop:RProp)
// {
//     if(!name){
//         return null;
//     }
//     const parts=name.split('.');
//     const ext=parts[parts.length-1].replaceAll('/','');
//     return `public/${type.Name}.${prop.Name}.${uuid()}.${ext}`;
// }

// const fileExts=['.png','.jpg','.jpeg','.bmp','.gif','.tiff','.tif'];

// function isImage(path:any){
//     const ln=(path+'').toLowerCase();
//     return fileExts.some(e=>ln.endsWith(e));
// }

// function FileInput({
//     data,
//     prop,
//     onChange,
//     type,
//     beforeSubmit
// }:InputProps)
// {



//     const [value,setValue]=useState(data[prop.Name]);
//     const [preview,setPreview]=useState('');
//     const file=useRef<File|null>(null);

//     const _onChange=useCallback((files:FileList|null)=>{
//         file.current=files?.item(0)||null;
//         const path=getStoragePath(file.current?.name,type,prop);
//         onChange(prop,path);
//         setValue(path);

//         if(isImage(path) && file.current){
//             setPreview(URL.createObjectURL(file.current));
//         }else{
//             setPreview('');
//         }

//     },[prop,onChange,data,type])

//     const {firebase,storage}=useApp();
//     useEffect(()=>{
//         const handler:SubmitHandler=async (provider,type,data)=>{
//             const f=file.current;
//             const path:string=data[prop.Name];
//             if(!path || !f){
//                 return;
//             }

//             await firebase.storage.ref().child(path).put(f,{contentType:f.type});
//             setPreview('');

//         }
//         beforeSubmit.push(handler);
//         return ()=>{
//             aryRemoveItem(beforeSubmit,handler);
//         }
//     },[beforeSubmit,firebase,prop]);
//     const isImg=isImage(value);


//     return (
//         <div className="admin-file-input">
//             {isImg?
//                 <div>
//                     <img alt={prop.Name} src={preview?preview:storage.getUrl(value)}/>
//                 </div>
//             :
//                 <div>{value}</div>
//             }
//             <input
//                 type="file"
//                 placeholder={prop.Name}
//                 onChange={e=>_onChange(e.target.files)}/>
//         </div>
//     )

// }


let enumFlagsIndex=0;

function EnumFlags({
    provider,
    data,
    prop,
    onChange

}:InputProps)
{

    const et=provider.ctx?.Enums.find(e=>e.FullName===prop.TypeFullName);
    const [value,setValue]=useState(data[prop.Name]);

    const _onChange=useCallback((v:number)=>{
        onChange(prop,v?Number(v):null);
        setValue(data[prop.Name])
    },[prop,onChange,data]);

    const id=useMemo(()=>enumFlagsIndex++,[]);

    if(!et){
        return null;
    }

    const name='EnumFlags-'+prop.Name+'-'+id;

    return (
        <ul className="admin-input-flags">
            {et.Values.map(v=>!v.Value?null:(
                <li key={v.Value}>
                    <input
                        id={name+'-'+v.Value}
                        type="checkbox"
                        checked={(value&v.Value)?true:false}
                        onChange={(e)=>_onChange(e.target.checked?value|v.Value:value&~v.Value)} />
                    <label htmlFor={name+'-'+v.Value}>{v.Name}</label>
                </li>
            ))}
        </ul>
    );
}

function EnumSelect({
    provider,
    data,
    prop,
    onChange,
    forCreate

}:InputProps)
{

    const et=provider.ctx?.Enums.find(e=>e.FullName===prop.TypeFullName);

    const [value,setValue]=useState(data[prop.Name]);
    const strValue=(value===undefined||value===null)?'':value.toString();

    const _onChange=useCallback((v:string)=>{
        onChange(prop,v?Number(v):null);
        setValue(data[prop.Name])
    },[prop,onChange,data]);

    return (
        <select className="admin-select" value={strValue} onChange={e=>_onChange(e.target.value)}>
            {(prop.IsNullable||forCreate)&&<option value="">(none)</option>}
            {et?.Values.map(v=>(
                <option key={v.Name} value={v.Value.toString()}>{v.Name}</option>
            ))}
        </select>
    )
}
