import AsyncObjStore from './AsyncObjStore';
import { delayAsync } from './oc-admin-lib';

export default class AsyncObjStoreWeb implements AsyncObjStore
{
    prefix:string;

    constructor(prefix:string)
    {
        this.prefix=prefix;
    }

    async loadAsync<T>(key:string):Promise<T|null>{
        await delayAsync(1);
        const r=window.localStorage.getItem(this.prefix+'::'+key);
        if(r){
            return JSON.parse(r) as T;
        }else{
            return null;
        }
    }

    async loadOrDefaultAsync<T>(key:string,defaultValue:T):Promise<T>{
        await delayAsync(1);
        const r=window.localStorage.getItem(this.prefix+'::'+key);
        if(r){
            return JSON.parse(r) as T;
        }else{
            return defaultValue;
        }
    }

    async saveAsync<T>(key:string, value:T):Promise<void>{
        await delayAsync(1);
        window.localStorage.setItem(this.prefix+'::'+key,JSON.stringify(value));
    }

    async CLEAR_ALL_DATA_FROM_STORE_ASYNC()
    {
        await delayAsync(1);
    }
}