import React from 'react';
import Link from '../components/Link';
import Page from '../components/Page';

export default function DashboardScreen()
{

    return (
        <Page>
            <h1>Dashboard</h1>
            <ul>
                <li><Link to="/upload">Uploads</Link></li>
                <li><Link to="/page-mgr">Page Manager</Link></li>
                <li><Link to="/db">Database</Link></li>
            </ul>
        </Page>
    )

}
