import React from 'react';
import Screens from '../screens/_Screens';


export default function MainLayout()
{

    return (
        <div id="main-layout">
            <div id="main-layout-screens">
                <Screens/>
            </div>
        </div>
    )

}
