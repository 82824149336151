import { createFFmpeg, fetchFile, FFmpeg } from "@ffmpeg/ffmpeg";
import { guid } from "./oc-admin-lib";
import { Upload } from "./oc-admin-types";
import OcAdminApp from "./OcAdminApp";
import { CreateMediaRequest, UploadContentRequest } from "./OcTypes";
import { Media, MediaAttachmentType, MediaType, Message } from "./OpenCanvasTypes";

const durationReg=/duration\s*:\s([\d:.]+)*/i;
const sizeReg=/stream.*video.*\D(\d+)x(\d+)/i;

let _ff:FFmpeg|null=null;
async function getFfAsync()
{
    if(!_ff){
        _ff=createFFmpeg();
        await _ff.load();
    }
    return _ff;
}

function resetLogger()
{
    _ff?.setLogger(()=>{/* */})
}

export default class OcAdminMediaManager
{
    private readonly app:OcAdminApp;

    

    constructor(app:OcAdminApp)
    {
        this.app=app;
    }

    public async createUploadAsync(file:File):Promise<Upload>
    {

        const id=guid();

        const out=id+'-output.jpg';

        const ffmpeg=await getFfAsync();

        let duration:number=Number.NaN;
        let width:number=Number.NaN;
        let height:number=Number.NaN;

        ffmpeg.setLogger(({type,message})=>{
            const dur=durationReg.exec(message);
            if(dur){
                const parts=dur[1].split(':');
                duration=Number(parts[0])*60*60*1000+Number(parts[1])*60*1000+Number(parts[2])*1000;

            }
            const size=sizeReg.exec(message);
            if(size){
                width=Number(size[1]);
                height=Number(size[2]);
            }
            //console.log(type,message);
        });

        ffmpeg.FS('writeFile', id, await fetchFile(file));

        await ffmpeg.run('-i',id,'-vframes','1','-an','-ss','0.6',out);

        const data = ffmpeg.FS('readFile', out);

        const previewBlob=new Blob([data.buffer],{type:'image/jpeg'});

        if(isNaN(duration)){
            throw new Error('Unable to detect duration');
        }

        if(isNaN(width)){
            throw new Error('Unable to detect width');
        }

        if(isNaN(height)){
            throw new Error('Unable to detect height');
        }

        const upload:Upload={
            id,
            file,
            name:file.name,
            url:URL.createObjectURL(file),
            previewBlob,
            previewUrl:URL.createObjectURL(previewBlob),
            width,
            height,
            duration,
            order:0,
            size:file.size
        }

        resetLogger();

        return upload;
    }

    public async uploadAsync(upload:Upload, progress?:ProgressCallback)
    {
        const request:CreateMediaRequest={
            TargetUserId:upload.userId,
            DoNotAssignToCanvas:false,
            Type:MediaType.Video,
            Name:upload.name||undefined,
            Description:upload.description||undefined,
            Length:upload.duration||0,
            Width:upload.width||0,
            Height:upload.height||0,
            Tags:upload.tags?.split(',').map(t=>t.trim()).filter(t=>t)
        }

        const message=await this.app.api.postAsync<Message>('Media/Message',request);
        const media=message.Media;
        if(!media){
            throw new Error("Created message did not return a media item");
        }

        const mediaId=media.Id;

        const previewSize=1000000;
        await this.uploadMediaChunkAsync(
            mediaId,MediaType.Image,upload.file,MediaAttachmentType.None,upload.userId,
            (sent,size)=>progress?.(upload,sent,size+previewSize));

        if(upload.previewBlob){
            await this.uploadMediaAsync(
                mediaId,MediaType.Image,upload.previewBlob,MediaAttachmentType.Thumbnail,upload.userId);
        }
        progress?.(upload,upload.size+previewSize,upload.size+previewSize);
    }

    getCdnUrl(path:string|null|undefined):string|null
    {
        if(!path){
            return null;
        }
        if(path.indexOf('://')===-1){
            if(!this.app.clientConfig.CdnBaseUrl){
                return null;
            }
            return this.app.clientConfig.CdnBaseUrl+path;
        }else{
            return path;
        }
    }

    private uploadMediaAsync(
        mediaId:number,
        mediaType:MediaType,
        value:Blob,
        attachmentType:MediaAttachmentType=MediaAttachmentType.None,
        userId?:number):Promise<Media>
    {
        return this.app.api.uploadFileAsync(
            `Media/Upload/${mediaId}/${MediaType[mediaType]}`+
            (attachmentType?'/'+MediaAttachmentType[attachmentType]:'')+
            (userId?'?userId='+userId:''),
            value);
    }

    private async uploadMediaChunkAsync(
        mediaId:number,
        mediaType:MediaType,
        file:File,
        attachmentType:MediaAttachmentType=MediaAttachmentType.None,
        userId?:number,
        progress?:ProgressChunkCallback):Promise<Media>
    {

        const uploadId=guid();

        const count=Math.ceil(file.size/chunkSize);
        let index=0;

        const request:UploadContentRequest={
            UserId:userId,
            MediaId:mediaId,
            Type:mediaType,
            ContentType:file.type,
            Index:index,
            Count:count,
            Size:file.size,
            UploadId:uploadId,
            AttachmentType:attachmentType
        }

        let media:Media|null=null;

        let sent=0;

        progress?.(sent,file.size);

        while(index<count){
            sent+=chunkSize;
            if(sent>file.size){
                sent=file.size;
            }
            media=await this.app.api.uploadFileAsync(
                'Media/Upload',
                file.slice(index*chunkSize,sent,file.type),
                undefined,
                undefined,
                request);
            index++;
            request.Index=index;
            progress?.(sent,file.size);

        }

        return media as Media;
    }
}

const chunkSize=4000000;

type ProgressChunkCallback=(sent:number, size:number)=>void;

export type ProgressCallback=(upload:Upload, sent:number, size:number)=>void;