import { ReflectConfig } from "../admin/lib/reflect-admin";

const nameMaxLength=20;

export const reflectConfig:ReflectConfig={
    apiPrefix:'CRUD/',
    typesPath:'Reflect',
    propConfigs:[
        {name:'Key'},
        {name:'Order'},
        {name:'Name',listMaxLength:nameMaxLength},
        {name:'Title',listMaxLength:nameMaxLength},
        {name:'SubTitle',listMaxLength:nameMaxLength},
        {name:'Email'},
        {name:'FirstName',listMaxLength:nameMaxLength},
        {name:'LastName',listMaxLength:nameMaxLength},
        {name:'Notify'},
        {name:'IsAdmin'},
        {name:'Type'},
        {name:'Value'},
        {name:'Category'},
        {name:'Message'},
        {name:'Comment'},
        {name:'Company'},
        {name:'Approved'},
        {name:'Hidden'},
        {name:'City'},
        {name:'State'},
        {name:'Zip'},
        {name:'Address1'},
        {name:'Address2'},
        {name:'Lat'},
        {name:'Lng'},
        {name:'CommunityId'},
        {name:'Description'},
        {name:'UseOfProceedsDescription'},
        {name:'Badges'},
        {name:'GoalUSD'},
        {name:'PotentialUSD',isReadonly:true,type:'Project'},
        {name:'CommittedUSD',isReadonly:true,type:'Project'},
        {name:'PotentialUSD'},
        {name:'CommittedUSD'},
        {name:'WhIndex'},
        {name:'RoiRating'},
        {name:'InvestorCount',isReadonly:true},
        {name:'LikeCount',isReadonly:true},
        {name:'Created'},
        {name:'ImagePath'},
    ]
}
