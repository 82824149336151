import { createBrowserHistory, BrowserHistory as History } from "history";

export default class OcAdminNavManager
{
    public readonly history:History;

    constructor()
    {
        this.history=createBrowserHistory();
    }

    public push(path:string){
        this.history.push(path);
    }

    public pop()
    {
        this.history.back();
    }
}
