import React from 'react';

interface AdminLoadingProps
{
    title?:string,
    slim?:boolean;
}
export default function AdminLoading({
    slim,
    title='Loading...'
}:AdminLoadingProps)
{

    return (
        <div className={"admin-loading"+(slim?' admin-loading-slim':'')}>
            {title}
        </div>
    )

}
