import React from 'react';
import Link from '../../components/Link';
import { getPluralName } from '../lib/reflect-admin';
import { RType } from '../lib/reflect-type';

interface AdminTrailProps
{
    container?:boolean;
    dashboard?:boolean;
    type?:RType;
}

export default function AdminTrail({
    container,
    dashboard,
    type
}:AdminTrailProps){

    return (
        <div className={"admin-trail"+(container?' admin-trail-container':'')}>
            <Link to="/">Dashboard</Link>{' / '}
            {dashboard&&<>
                <Link to="/db">Database</Link>{' / '}
            </>}
            {type&&<>
                <Link to={"/db/"+type.Name}>{getPluralName(type.Name)}</Link>{' / '}
            </>}
        </div>
    )

}
