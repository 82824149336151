import React, { UIEvent, useCallback } from 'react';
import { classes } from '../lib/oc-admin-lib';

interface PageProps
{
    noHeadSpace?:boolean;
    noContainer?:boolean;
    noScroll?:boolean;
    hScroll?:boolean;
    onScroll?:(offset:number)=>void;
    children:any;
}

export default function Page({
    noHeadSpace,
    noContainer,
    noScroll,
    hScroll,
    onScroll,
    children
}:PageProps){

    const _onScroll=useCallback((e:UIEvent)=>{
        if(!onScroll){
            return;
        }
        try{
            const div=e.target as HTMLDivElement;
            onScroll(div.scrollTop);
        }catch(ex){
            console.warn('Page scroll callback failed',ex);
        }

    },[onScroll]);

    if(!noContainer){
        children=(
            <div className="container">
                {children}
            </div>
        )
    }

    return (
        <div
            className={classes(
                'page relative col flex1',
                !noHeadSpace&&'nav-bar-top-padding',
                !noScroll&&'scroll',
                hScroll&&'h-scroll')}
            onScroll={onScroll?_onScroll:undefined}>
            {children}
        </div>
    )

}
