import AsyncObjStore from "./AsyncObjStore";
import AsyncObjStoreWeb from "./AsyncObjStore-web";
import { OcAdminConfig } from "./oc-admin-types";
import OcAdminApiManager from "./OcAdminApiManager";
import { OcAdminAuthManager } from "./OcAdminAuthManager";
import OcAdminNavManager from "./OcAdminNavManager";
import OcAdminMediaManager from "./OcAdminMediaManager";
import { ClientAppConfig } from "./OcTypes";
import { ReflectProvider } from "../admin/lib/reflect-admin";
import { reflectConfig } from "./reflect-config";

export default class OcAdminApp
{
    public readonly config:Readonly<OcAdminConfig>;

    public readonly nav:OcAdminNavManager;

    public readonly store:AsyncObjStore;

    public readonly api:OcAdminApiManager;

    public readonly auth:OcAdminAuthManager;

    public readonly media:OcAdminMediaManager;
    
    public readonly reflect:ReflectProvider;

    private _clientConfig:ClientAppConfig|null=null;
    public get clientConfig():ClientAppConfig{return this._clientConfig as ClientAppConfig}

    constructor(config:OcAdminConfig)
    {
        this.config=config;
        this.nav=new OcAdminNavManager();
        this.store=new AsyncObjStoreWeb('oc-admin');
        this.api=new OcAdminApiManager(config.ApiBaseUrl,true);
        this.auth=new OcAdminAuthManager(this);
        this.media=new OcAdminMediaManager(this);
        this.reflect=new ReflectProvider(this,reflectConfig);
    }

    public async initAsync()
    {
        await this.auth.initAsync();

        this._clientConfig=await this.api.getAsync('App/Config');

        await this.reflect.config
    }

    public dispose()
    {
        this.auth.dispose();
    }
}