


export interface Canvas
{
    Id:number;

    Guid?:string;

    Name?:string;

    Created?:string|Date;

    Order?:number;

    Type?:CanvasType;

    UserId?:number;

    Messages?:Message[];

    IsPublic?:boolean;

    IsDynamic?:boolean;

    Tags?:Tag[];


}



export interface Message
{
    Id:number;

    Name?:string;

    Guid?:string;

    Type:MessageType;

    Created?:string|Date;

    IsPublic?:boolean;

    Order?:number;

    Text?:string;

    X?:number;

    Y?:number;

    Width?:number;

    Height?:number;

    LikeCount?:number;

    CanvasId?:number;

    UserId?:number;

    HasComments?:boolean;

    Comments?:MessageComment[];

    HasContent?:boolean;

    Content?:MessageContent[];

    MediaId?:number|null;
    Media?:Media;

    HasReplies?:boolean;

    Title?:string;

    SubTitle?:string;

    Description?:string;

    GameId?:number|null;

    ChallengeTimeLimitMinutes?:number|null;

    ChallengeStatus?:ChallengeStatus|null;

    Tags?:Tag[];


}



export interface User
{
    Id:number;

    Guid?:string;

    IsPublic?:boolean;

    Username?:string;

    DisplayName?:string;

    IsCertifiedTeacher?:boolean;

    IsTeacher?:boolean;

    IsAdult?:boolean;

    IsParent?:boolean;

    IsKid?:boolean;

    ProfilePicture?:string;

    HeroImagePath?:string;

    Tags?:Tag[];


}



export interface Relationship
{
    Id:number;

    Type:RelationshipType;

    Name?:string;

    IsModerator?:boolean;

    IsRootModerator?:boolean;

    Created?:string|Date;

    Hidden?:boolean;

    RequestId?:number|null;

    OwnerId?:number;
    Owner?:User;

    TargetId?:number|null;
    Target?:User;

    TargetGroupId?:number|null;
    TargetGroup?:Group;

    AllowGroupInvite?:boolean;

    GroupCanViewAllContent?:boolean;

    CanPostToGroup?:boolean;


}



export interface Media
{
    Id:number;

    Guid?:string;

    Name?:string;

    UserId?:number;

    Type:MediaType;

    Status?:MediaStatus;

    Created?:string|Date;

    Description?:string;

    IsPublic?:boolean;

    Path?:string;

    PublicPath?:boolean;

    AuthorizedPath?:string;

    AuthorizedPathExpiration?:string|Date|null;

    ThumbnailPath?:string;

    PublicThumbnailPath?:boolean;

    ThumbnailAuthorizedPath?:string;

    ThumbnailAuthorizedPathExpiration?:string|Date|null;

    ContentType?:string;

    Length?:number;

    Size?:number;

    Width?:number;

    Height?:number;

    Color?:string;

    Tags?:Tag[];

    Overlays?:string;


}



export interface MessageComment
{
    Id:number;

    Guid?:string;

    Content?:string;

    Created?:string|Date;

    UserId?:number;

    MessageId?:number;


}



export interface NotificationDevice
{
    Id:number;

    Created?:string|Date;

    LastUpdate?:string|Date;

    UserId?:number|null;

    Type?:NotificationType;

    DeviceId?:string;

    Enabled?:boolean;

    Auto?:boolean;


}



export enum NotificationType
{
    Other=0,
    APN=1,

}



export interface MessageReply
{
    Id:number;

    Created?:string|Date;

    ReplyId?:number;
    Reply?:Message;

    ReplyToId?:number;


}



export interface MessageContent
{
    Id:number;

    Order?:number;

    MessageId?:number;

    DataId?:number|null;
    Data?:UserData;

    MediaId?:number|null;
    Media?:Media;

    Layout:ContentLayout;


}



export interface Group
{
    Id:number;

    Guid:string;

    Name:string;

    Type:GroupType;

    Hidden?:boolean;

    IconPath?:string;

    HeroImagePath?:string;

    Created?:string|Date;

    Tags?:Tag[];

    ShortDescription?:string;

    Description?:string;

    ColorHex?:string;

    Category?:string;


}



export interface Block
{
    Id:number;

    ObjType?:OcObjType;

    Type:BlockType;

    Comment?:string;

    BlockedGuid?:string;

    BlockedById?:number|null;

    UserId?:number|null;

    Created?:string|Date;


}



export enum BlockType
{
    None=0,
    WaitingApproval=1,
    Inappropriate=2,

}



export interface UserData
{
    Id:number;

    Guid?:string;

    Key?:string;

    UserId?:number;

    Status?:DataStatus;

    Type:DataType;

    ContentType?:DataContentType;

    IsPublic?:boolean;

    Created?:string|Date;

    Content?:string;

    Int1?:number;

    Int2?:number;

    Double1?:number;

    Double2?:number;

    String1?:string;

    String2?:string;


}



export enum OcObjType
{
    None=0,
    User=1,
    Canvas=2,
    Message=3,
    Media=4,
    RichText=5,
    MessageComment=6,

}



export interface IBlockable
{
    Guid?:string;


}



export interface UserRequest
{
    Id:number;

    Guid?:string;

    Type:UserRequestType;

    ShareMedium?:ShareMedium;

    InPerson?:boolean;

    IsPublic?:boolean;

    Created?:string|Date;

    LastUpdate:string|Date;

    Expires?:string|Date|null;

    HitCount?:number;

    IsTemplate?:boolean;

    TemplateCopySenderState?:boolean;

    TemplateCopySenderModeratorState?:boolean;

    SourceId?:number|null;

    AudienceId?:number|null;

    RelationshipType?:RelationshipType|null;

    RelationshipName?:string;

    OwnerIsModerator:boolean|null;

    TargetIsModerator:boolean|null;

    MessageForReceiver?:string;

    MessageForSender?:string;

    MessageForReceiverModerator?:string;

    MessageForSenderModerator?:string;

    ReceiverNotificationState?:UserRequestNotificationState;

    ReceiverModeratorNotificationState?:UserRequestNotificationState;

    SenderNotificationState?:UserRequestNotificationState;

    SenderModeratorNotificationState?:UserRequestNotificationState;

    ClientReceiverNotificationState?:UserRequestNotificationState;

    ClientReceiverModeratorNotificationState?:UserRequestNotificationState;

    ClientSenderNotificationState?:UserRequestNotificationState;

    ClientSenderModeratorNotificationState?:UserRequestNotificationState;

    State:UserRequestState;

    SenderState?:UserRequestState;

    ReceiverState?:UserRequestState;

    SenderModeratorState?:UserRequestState;

    ReceiverModeratorState?:UserRequestState;

    AnonoymousReceiverId?:number|null;

    SenderId?:number|null;
    Sender?:User;

    ReceiverId?:number|null;
    Receiver?:User;

    SenderModeratorId?:number|null;

    ReceiverModeratorId?:number|null;

    TargetSignInId?:number|null;

    TargetRelationshipTargetId?:number|null;

    TargetRelationshipOwnerId?:number|null;

    TargetGroupId?:number|null;
    TargetGroup?:Group;

    TargetMessageId?:number|null;

    TargetChallengeId?:number|null;


}



export enum CanvasType
{
    Default=0,
    UserDefault=1,
    GroupDefault=2,
    Chat=3,

}



export enum MediaType
{
    None=0,
    Video=1,
    Image=2,
    Audio=3,

}



export enum MediaAttachmentType
{
    None=0,
    Thumbnail=1,

}



export interface TrackedEvent
{
    Id:number;

    UserId?:number|null;

    InstId?:string|null;

    Created?:string|Date;

    AudienceId?:number|null;

    LocType?:TrackedLocationType;

    Type:EventType;

    Country?:string;

    Region?:string;

    City?:string;

    Lat?:number|null;

    Lon?:number|null;

    Route?:string;

    AppVersion?:string;

    Int1?:number;

    Int2?:number;

    Int3?:number;

    Double1?:number;

    String1?:string;

    String2?:string;


}



export enum EventType
{
    Unknown=0,
    Error=1,
    StartApp=2,
    FocusApp=3,
    BlurApp=4,
    SignIn=5,
    SignOut=6,
    ButtonPress=7,
    MenuOpen=8,
    MenuClose=9,
    OpenBroadcastPicker=10,
    History=11,
    SetProfilePicture=12,
    StartRecordVideo=13,
    StopRecordVideo=14,
    UploadQueued=15,
    UploadCanceled=16,
    UploadComplete=17,
    ViewMedia=18,
    WatchedMedia=19,
    ArFilterSelected=20,
    TutorialStart=5000,
    TutorialComplete=5001,
    TutorialCancel=5002,
    TutorialStep=5003,
    SetGroupIcon=6000,
    StartChallenge=7000,
    CompleteChallenge=7001,

}



export enum MediaStatus
{
    Active=0,
    Inactive=1,
    Deleting=2,
    Uploading=3,

}



export interface UploadState
{
    Id:number;

    UploadId?:string;

    State?:string;

    Path?:string;

    UserId?:number;

    Created?:string|Date;

    LastUsed?:string|Date;

    Size?:number;

    ContentType?:string;

    Uploaded?:number;

    Complete?:boolean;

    LastError?:string;

    MediaId?:number|null;

    AttachmentType?:MediaAttachmentType|null;


}



export enum TrackedLocationType
{
    None=0,
    Other=1,
    GPS=2,
    DeviceService=3,
    IP=4,

}



export interface IUserObj
{
    Id:number;

    UserId?:number;

    IsPublic?:boolean;


}



export enum DataContentType
{
    None=0,
    Text=1,
    Json=2,
    Markdown=3,
    Html=4,

}



export enum DataType
{
    None=0,
    Other=1,
    List=2,

}



export enum DataStatus
{
    Active=0,
    Inactive=1,
    Deleting=2,

}



export enum ContentLayout
{
    Fill=0,
    Hidden=1,

}



export enum MessageType
{
    Default=0,
    Challenge=1,
    Reply=2,

}



export interface MediaFilter
{
    Id:number;

    Name?:string;

    Type?:MediaFilterType;

    Path?:string;

    IconPath?:string;

    Created?:string|Date;


}



export interface MediaFilterGroup
{
    Id:number;

    Order?:number;

    Name?:string;

    Created?:string|Date;

    Active?:boolean;

    ForegroundColor?:string;

    BackgroundColor?:string;

    Filters?:MediaFilterGroupItem[];


}



export interface MediaFilterGroupItem
{
    Id:number;

    FilterId?:number;
    Filter?:MediaFilter;

    GroupId?:number;

    Order?:number;


}



export enum MediaFilterType
{
    None=0,
    Face=1,
    Tracker=2,

}



export enum UserRequestType
{
    Unknown=0,
    Empty=1,
    SignIn=100,
    AddFriend=200,
    JoinGroup=201,
    RegisterChild=202,
    RegisterWithParent=203,
    SharePost=300,
    Challenge=400,

}



export enum RelationshipType
{
    None=0,
    Friend=1,
    Parent=2,
    Child=3,
    GroupMember=5,
    Chat=6,

}



export enum GroupType
{
    Default=0,
    Family=1,
    Classroom=2,
    Chat=3,

}



export interface GroupCanvas
{
    Id:number;

    Created?:string|Date;

    GroupId:number;

    CanvasId:number;


}



export interface NotificationScheduleItem
{
    Id:number;

    ScheduleId?:number;

    IntervalType?:IntervalType;

    Interval?:number;

    OffsetSeconds?:number;

    ToleranceSeconds?:number;

    Active?:boolean;

    UtcTime?:boolean;

    Start?:string|Date;

    End?:string|Date|null;


}



export enum NotificationScheduleType
{
    None=0,
    NewContent=1,

}



export interface NotificationScheduleLog
{
    Id:number;

    ScheduledDate?:string|Date;

    ActualDate?:string|Date;

    TimeZone?:number;

    ItemId?:number;

    UserId?:number|null;


}



export enum IntervalType
{
    Second=0,
    Minute=1,
    Hour=2,
    Day=3,
    Week=4,
    Month=5,

}



export interface NotificationSchedule
{
    Id:number;

    Name?:string;

    Created?:string|Date;

    Type?:NotificationScheduleType;


}



export interface NotificationScheduleTarget
{
    Id:number;

    ScheduleId?:number;

    UserId?:number|null;

    GroupId?:number|null;


}



export enum UserRequestState
{
    Unknown=0,
    New=1,
    UnknownEnd=99,
    Pending=100,
    PendingEnd=199,
    Approved=200,
    AutoApproved=201,
    ApprovedPrefNotify=202,
    ApprovedPrefInPerson=203,
    ApprovedPrefNoRestriction=204,
    SelfApproved=202,
    ApprovedEnd=299,
    Rejected=300,
    Cancled=301,
    Expired=302,
    RejectedEnd=399,

}



export enum UserRequestMember
{
    None=0,
    Receiver=1,
    ReceiverModerator=2,
    Sender=4,
    SenderModerator=8,

}



export enum UserRequestNotificationState
{
    None=0,
    New=1,
    Approved=2,
    Rejected=4,

}



export interface UserRequestSettings
{
    Id:number;

    Name?:string;

    IsDefault?:boolean;

    IsTemplate?:boolean;

    Order?:number;

    TemplateId?:number|null;

    UserId?:number|null;

    SendAddFriend?:UserRequestPref;

    ReceiveAddFriend?:UserRequestPref;

    SendJoinGroup?:UserRequestPref;

    ReceiveJoinGroup?:UserRequestPref;

    SendSharePost?:UserRequestPref;

    ReceiveSharePost?:UserRequestPref;

    SendChallenge?:UserRequestPref;

    ReceiveChallenge?:UserRequestPref;


}



export enum UserRequestPref
{
    None=0,
    RequirePermission=1,
    Notify=2,
    AllowInPerson=4,
    NoRestriction=8,

}



export interface MediaOverlay
{
    OverlayId:string;

    Type:string;

    ContentType?:string;

    Content?:string;

    Thumbnail?:string;

    Style?:any;

    ContentStyle?:any;

    TextStyle?:any;


}



export interface CardGroup
{
    Id:number;

    Name?:string;

    Order?:number;

    Condition?:UserCondition;

    Cards?:CardGroupLink[];

    Tags?:string;


}



export interface Card
{
    Id:number;

    Type?:CardType;

    Size?:CardSize;

    Link?:string;

    Action?:AppAction|null;

    Title?:string;

    Description?:string;

    TextBackgroundColor?:string;

    TextColor?:string;

    TextAlignment?:FlexAlignment;

    ButtonText?:string;

    ButtonTextColor?:string;

    ButtonColor?:string;

    ButtonAlignment?:FlexAlignment;

    Content?:string;

    ContentType?:string;

    ContentPosition?:BoxPosition;

    ContentSize?:number|null;

    ContentPadding?:number|null;

    ContentMargin?:number;

    BackgroundImageCdnPath?:string;

    BackgroundColor?:string;

    RowSpan?:number;

    ColSpan?:number;


}



export enum CardType
{
    Default=0,

}



export enum BoxPosition
{
    Absolute=0,
    Top=1,
    Right=2,
    Bottom=3,
    Left=4,

}



export enum FlexAlignment
{
    Fill=0,
    Start=1,
    Center=2,
    End=3,

}



export enum AppAction
{
    None=0,

}



export enum CardSize
{
    Small=0,
    Medium=1,
    Large=2,

}



export interface CardGroupLink
{
    Id:number;

    CardId?:number;
    Card?:Card;

    GroupId?:number;

    Order?:number;

    Condition?:UserCondition;


}



export enum UserCondition
{
    None=0,
    Guest=1,
    User=2,
    NewUser=4,
    Child=8,
    Parent=16,
    NoConnections=32,
    HasConnections=64,

}



export interface RandomName
{
    Id:number;

    Set?:number;

    Value?:string;


}



export interface GameData
{
    Id:number;

    Name?:string;

    IsTemplate?:boolean;

    Type?:GameDataType;

    ObjectType?:GameObjectType;

    Data?:string;

    RewardId?:number|null;

    UserId?:number|null;


}



export enum GameDataType
{
    Unknown=0,
    UserDefault=1,
    NewGame=2,

}



export enum GameObjectType
{
    Game=0,
    Map=1,
    Block=2,
    Character=3,

}



export interface Reward
{
    Id:number;

    IsTemplate?:boolean;

    Type?:RewardType;

    Name?:string;

    Description?:string;

    UserId?:number|null;

    MessageTemplateId?:number|null;

    ChallengeId?:number|null;


}



export enum RewardType
{
    Default=0,

}



export interface UserChallenge
{
    Id:number;

    GroupId?:number;

    ReplyMessageId?:number|null;

    UserId?:number;

    Status?:UserChallengeStatus;


}



export interface ChallengeGroup
{
    Id:number;

    MessageId?:number;

    Users?:UserChallenge[];

    StartTime?:string|Date|null;

    EndTime?:string|Date|null;

    UsersLocked?:boolean;


}



export enum UserChallengeStatus
{
    Open=0,
    Complete=1,
    Canceled=2,
    Expired=3,

}



export enum ChallengeStatus
{
    Inactive=0,
    Active=1,

}



export enum ShareMedium
{
    App=0,
    Message=1,
    Email=2,
    QR=3,
    Clipboard=4,
    Other=100,

}



export interface AudioSource
{
    Id:number;

    Name?:string;

    Description?:string;

    Type?:AudioSourceType;

    Path?:string;

    ImagePath?:string;


}



export enum AudioSourceType
{
    Song=0,
    FX=1,

}



export interface Tag
{
    Id:number;

    Value?:string;

    Order?:number;

    Owner?:boolean;

    MessageId?:number|null;

    CanvasId?:number|null;

    MediaId?:number|null;

    UserId?:number|null;

    GroupId?:number|null;


}



export interface Audience
{
    Id:number;

    Name?:string;

    Description?:string;

    Guid?:string;

    IsDefault?:boolean;

    AppBundleId?:string;

    IosInstallUrl?:string;

    AndroidInstallUrl?:string;


}



export interface AudienceMember
{
    Id:number;

    UserId?:number;

    AudienceId?:number;


}
