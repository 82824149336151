import './Admin.scss';
import React, { useEffect, useMemo } from 'react';
import Page from '../../components/Page';
import { Screen } from '../../components/Screen';
import AdminEdit from './AdminEdit';
import AdminList from './AdminList';
import AdminDashboard from './AdminDashboard';
import AdminLoading from './AdminLoading';
import { useApp, useProperty, useUser } from '../../lib/oc-admin-hooks';

export function AdminRoutes()
{
    const {reflect}=useApp();
    const user=useUser();
    const ctx=useProperty(reflect,'ctx');

    const routes=useMemo(()=>{
        if(!ctx || !user){
            return null;
        }
        const screens:any[]=[];
        for(const type of ctx.Types){

            screens.push(
                <Screen
                    key={type.FullName+':list'}
                    exact
                    prepend
                    path={'/db/'+type.Name}
                    render={()=>(
                        <Page hScroll noContainer>
                            <AdminList type={type} provider={reflect} />
                        </Page>
                    )}/>
            );

            screens.push(
                <Screen
                    key={type.FullName+':edit'}
                    exact
                    prepend
                    path={'/db/'+type.Name+'/:id'}
                    render={({id})=>(
                        <Page>
                            <AdminEdit
                                type={type}
                                provider={reflect}
                                id={id==='create'?undefined:id}
                                create={id==='create'}/>
                        </Page>
                    )}/>
            );
        }
        screens.push(
            <Screen
                key=":dashboard"
                prepend
                exact
                path={'/db'}
                render={()=>(
                    <Page>
                        <AdminDashboard provider={reflect}/>
                    </Page>
                )}/>
        )
        return screens;
    },[ctx,reflect,user]);

    return (
        <>
            {routes}
            <Screen
                path={'/db'}
                render={()=><Page><Loader/></Page>}/>
        </>
    )

}


function Loader()
{
    const {reflect}=useApp();
    const user=useUser();
    useEffect(()=>{
        console.log('Load',user)
        if(user){
            reflect.loadCtxAsync();
        }
    },[reflect,user])
    return (
        <AdminLoading title={'Sign-in to continue'}/>
    )
}
