import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import MainLayout from './components/MainLayout';
import { OcAdminAppContext, useBreakpointBodyClasses, useInitApp } from './lib/oc-admin-hooks';
import ocAdminConfig from './oc-admin-config';

export default function App()
{
    const {app,error}=useInitApp(ocAdminConfig);

    useBreakpointBodyClasses();

    if(error){
        return (
            <div>Error - {error}</div>
        )
    }

    if(!app){
        return (
            <div>Initializing App</div>
        )
    }

    return (
        <OcAdminAppContext.Provider value={app}>
            <Router>
                <MainLayout/>
            </Router>
        </OcAdminAppContext.Provider>
    )
}
