import React from 'react';

interface AdminPromptProps
{
    title:any;
    children:any;
    show:boolean;
    closeRequested?:(show:boolean)=>void;
    row?:boolean;
}

export default function AdminPrompt({
    title,
    children,
    show,
    closeRequested,
    row=true
}:AdminPromptProps){

    if(!show){
        return null;
    }

    return (
        <div className="admin-prompt-wrapper">
            <div className="admin-prompt-bg" onClick={()=>closeRequested?.(false)} />
            <div className="admin-prompt">
                <h2>{title}</h2>
                <div className={'admin-prompt-body'+(row?' admin-prompt-body-row':'')}>
                    {children}
                </div>
            </div>
        </div>
    )

}
