export enum LogLevel
{
    info=0,
    warning=1,
    error=2
}

export interface LogEntry
{
    level:LogLevel;
    message:string;
    error?:any;
}

export class Logger
{
    public info(message:string,error?:any)
    {
        this.log({
            level:LogLevel.info,
            message,
            error
        });
    }

    public warn(message:string,error?:any)
    {
        this.log({
            level:LogLevel.warning,
            message,
            error
        });
    }

    public error(message:string,error?:any)
    {
        this.log({
            level:LogLevel.error,
            message,
            error
        });
    }

    public log(entry:LogEntry)
    {
        if(!entry){
            return;
        }
        //todo - send to api
        switch(entry.level){

            case LogLevel.warning:
                console.warn(entry.message,entry.error);
                break;

            case LogLevel.error:
                console.error(entry.message,entry.error);
                break;

            default:
                console.info(entry.message,entry.error);
                break;
        }
    }
}

const Log=new Logger();

export default Log;
