import './Screen.scss';
import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { matchPath } from 'react-router-dom';
import { Action } from 'history';
import { aryRemoveItem, delayAsync } from '../lib/oc-admin-lib';
import { useApp } from '../lib/oc-admin-hooks';

let nextId=1;
const transDelay=400;
type TransType='slide'|'fade';
type TransState='in'|'out';
const defaultTransType:TransType='slide';
const ReactScreenGroupContext=React.createContext<ScreenGroupContext|null>(null);

interface ScreenMatch
{
    id:number;
    path:string;
    children:any;
    exiting?:boolean;
    shouldExit?:boolean;
    entered?:boolean;
    transState:TransState;
    transType?:TransType;
    elem?:HTMLDivElement;
    pop:boolean;
    props?:RenderedScreenProps;
    firstRoute:boolean;
    transActive:boolean;
}

interface ScreenState
{
    matches:ScreenMatch[];
    render:()=>void;
    update:(path:string,props:any,isMatch:boolean,pop:boolean,isFirst:boolean)=>void;
    path:string;
    exact:boolean;
}

interface HistoryNode
{
    pathname: string;
    search: string;
    state: any;
    hash: string;
    key?: string;
    action: Action|null;
}

interface ScreenGroupContext
{
    screens:ScreenState[];
    add:(screen:ScreenState,prepend?:boolean)=>void;
    remove:(screen:ScreenState)=>void;
}

function setClass(match:ScreenMatch)
{

    if(!match.props || !match.elem){
        return;
    }

    const {
        className,
        transType=defaultTransType,
    }=match.props;

    match.elem.className=(
        'screen'+
        (className?' '+className:'')+
        ((match.firstRoute && match.transState==='in')?'':
            ` trans-${transType} trans-${transType}-${match.transState}`+
            (match.pop?' trans-pop':' trans-push')+
            (match.transActive?' trans-active':' trans-complete')
        )
    )
}

async function exit(match:ScreenMatch, state:ScreenState)
{
    match.shouldExit=true;
    if(match.exiting || !match.entered){
        return;
    }
    match.exiting=true;
    match.transState='out';
    match.transActive=true;
    setClass(match);

    await delayAsync(transDelay);

    aryRemoveItem(state.matches,match);
    state.render();
}

async function enter(match:ScreenMatch, state:ScreenState, elem:HTMLDivElement)
{
    if(match.elem){
        return;
    }
    match.elem=elem;
    match.transState='in';
    match.transActive=true;
    setClass(match);

    await delayAsync(transDelay);

    match.entered=true;
    match.transActive=false;
    setClass(match);

    if(match.shouldExit){
        exit(match,state);
    }
}






interface ScreenGroupProps
{
    children:any;
}

export function ScreenGroup({
    children
}:ScreenGroupProps)
{

    const {nav}=useApp();
    const [refresh,setRefresh]=useState(0);
    const [loc,setLoc]=useState<HistoryNode>(()=>({
        ...nav.history.location,
        action:null
    }));
    useEffect(()=>{
        const un=nav.history.listen((s)=>{
            console.log(s);
            setLoc({
                ...s.location,
                action:s.action
            })
        });
        return ()=>{un()}
    },[nav]);

    const first=useRef(true);

    const screens=useRef<ScreenState[]>([]).current;

    useEffect(()=>{
        if(!loc){
            return;
        }

        let matched=false;

        for(const s of screens){

            let isMatch:boolean;
            let props:any;
            let isFirst=false;

            if(matched){
                props={};
                isMatch=false;
            }else{
                const m=matchPath(loc.pathname,{
                    path:s.path,
                    exact:s.exact
                });

                if(m){
                    matched=true;
                    isMatch=true;
                    props=m.params;
                    if(first.current){
                        first.current=false;
                        isFirst=true;
                    }
                }else{
                    isMatch=false;
                    props={}
                }
            }
            s.update(s.path,props,isMatch,loc.action==='POP',isFirst);
        }

    },[loc,screens,refresh]);

    const ctx=useMemo<ScreenGroupContext>(()=>{

        let refreshId=0;
        const refreshRoutes=()=>{
            const id=++refreshId;
            setTimeout(()=>{
                if(id===refreshId){
                    setRefresh(v=>v+1);
                }
            },50);
        };

        return {
            screens,
            add:(screen,prepend)=>{
                if(prepend){
                    screens.unshift(screen);
                    refreshRoutes();
                }else{
                    screens.push(screen);
                }
            },
            remove:(screen)=>{
                aryRemoveItem(screens,screen);
                refreshRoutes();
            }
        }
    },[screens]);

    return (
        <ReactScreenGroupContext.Provider value={ctx}>
            {children}
        </ReactScreenGroupContext.Provider>
    )
}





interface ScreenProps
{
    className?:string;
    path:string;
    exact?:boolean;
    transType?:TransType;
    prepend?:boolean;
    /**
     * Renders a component for the screen. This function must be a pure functional component and
     * only depend of the props passed to it. Changes to render will be ignored
     */
    render:(props:any)=>any;
}

export function Screen({
    path,
    exact,
    prepend,
    render,
    ...props
}:ScreenProps){


    const mounted=useRef(true);
    useLayoutEffect(()=>{
        return ()=>{mounted.current=false}
    },[]);

    const renderRef=useRef(render);
    const [,reRender]=useState(0);

    const state=useMemo<ScreenState>(()=>{

        const matches:ScreenMatch[]=[];

        const render=()=>mounted.current&&reRender(v=>v+1);

        return {
            matches,
            path,
            exact:exact?true:false,
            render,
            update:(path,props,isMatch,pop,isFirst)=>{

                if(!isMatch && !state.matches.length){
                    return;
                }

                for(const m of state.matches){
                    if(!m.shouldExit){
                        m.pop=pop;
                    }
                    exit(m,state);
                }

                if(isMatch){

                    const m:ScreenMatch={
                        id:nextId++,
                        path,
                        children:renderRef.current(props),
                        transState:'in',
                        pop,
                        firstRoute:isFirst,
                        transActive:false
                    }

                    state.matches.push(m);
                }

                render();
            }
        }
    },[path,exact]);

    const ctx=useContext(ReactScreenGroupContext);
    useLayoutEffect(()=>{
        if(!ctx){
            return;
        }
        ctx.add(state,prepend);
        return ()=>{
            ctx.remove(state);
        }
    },[ctx,state,prepend]);

    return (
        <>
            {state.matches.map(match=>(
                <RenderedScreen
                    {...props}
                    key={match.id}
                    match={match}
                    state={state}>
                    {match.children}
                </RenderedScreen>
            ))}
        </>
    )


}






interface RenderedScreenProps extends Omit<ScreenProps,'render'|'path'|'exact'>
{
    children:any;
    match:ScreenMatch;
    state:ScreenState;
}

function RenderedScreen(props:RenderedScreenProps){
    const {match,state,transType,children}=props;
    match.transType=transType;
    match.props=props;
    return (
        <div ref={elem=>elem&&enter(match,state,elem)}>
            {children}
        </div>
    )

}
