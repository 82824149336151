import './Link.scss';
import React, { useCallback, MouseEvent } from 'react';
import { useApp } from '../lib/oc-admin-hooks';
import { classes } from '../lib/oc-admin-lib';

interface LinkProps
{

    /** Call when the Link is pressed */
    onClick?:()=>void;

    /** A route to navigate to when the Link is pressed */
    to?:string;

    className?:string;

    min?:boolean;

    children:any;
}

export default function Link({
    onClick,
    to,
    className,
    min,
    children
}:LinkProps){

    const {nav}=useApp();

    const _onClick=useCallback((e:MouseEvent)=>{
        if(e){
            e.preventDefault();
        }
        if(onClick){
            onClick();
        }
        if(to){
            nav.push(to);
        }
    },[to,onClick,nav]);

    return (
        <a className={classes('link',min&&'min',className)} href={to} onClick={_onClick}>{children}</a>
    )

}
