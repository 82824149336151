import React, { useContext } from 'react';
import { RCtx, RDataType, RProp, RType } from './reflect-type';
import EventEmitter from "events";
import OcAdminApp from '../../lib/OcAdminApp';
import { unused } from '../../lib/oc-admin-lib';

export interface ListResult{
    items:any[];
    total:number;
}

export interface PropConfig
{
    type?:string;
    name:string;
    isReadonly?:boolean;
    listMaxLength?:number;
}

export interface ReflectConfig
{
    apiPrefix?:string;
    typesPath?:string;
    propConfigs?:PropConfig[];
}

const shortList=[
    'Name','name',
    'Title','title',
    'Username','username',
    'DisplayName','displayName',
    'Id','id'
]

export type SubmitHandler=(provider:ReflectProvider,type:RType,data:any)=>Promise<void>

export class ReflectProvider extends EventEmitter {


    private _ctx:RCtx|null=null;
    public get ctx(){return this._ctx}

    public readonly app:OcAdminApp;

    private get http(){return this.app.api}

    public readonly config:ReflectConfig;

    private readonly prefix:string;

    constructor(app:OcAdminApp, config?:ReflectConfig)
    {
        super();
        this.app=app;
        this.config=config||{};
        this.prefix=config?.apiPrefix||'';
    }

    public getPropConfig(prop:RProp, type:RType):PropConfig|null
    {
        if(!prop || !type){
            return null;
        }
        return this.config.propConfigs?.find(
            p=>p.name===prop.Name && (!p.type || p.type===type.Name || p.type===type.FullName))||null;
    }

    async loadCtxAsync():Promise<RCtx>
    {
        if(this._ctx){
            return this._ctx;
        }
        this._ctx=await this.http.getAsync<RCtx>(this.config.typesPath||this.prefix+'Reflect');

        const order=this.config.propConfigs;
        if(order){
            for(const type of this._ctx.Types)
            {
                type.Props.sort((a,b)=>{
                    const ao=order.findIndex(
                        p=>p.name===a.Name && (!p.type || p.type===type.Name || p.type===type.FullName));
                    const bo=order.findIndex(
                        p=>p.name===b.Name && (!p.type || p.type===type.Name || p.type===type.FullName));

                    if(!ao && !bo){
                        return a.Name.localeCompare(b.Name);
                    }

                    if(ao===-1){
                        return 1;
                    }

                    if(bo===-1){
                        return -1;
                    }

                    return ao-bo;
                })
            }
        }

        this.emit('ctx');
        return this._ctx;
    }

    async getList(type:RType, offset: number, count:number):Promise<ListResult>
    {

        unused(offset);
        unused(count);

        const plural=getPluralName(type.Name);

        const items=(await this.http.getAsync<any[]>(this.prefix+plural))||[];

        if(items.length){
            const first=items[0];
            for(const prop of shortList){
                if(first && first[prop]!==undefined){
                    const type=typeof first[prop];
                    items.sort((a,b)=>{
                        switch(type){
                            case 'string':
                                return ((a[prop] as string)||'')?.localeCompare(b[prop]||'')||0;
                            case 'number':
                                return a[prop]-b[prop];
                            default:
                                return 0;
                        }
                    })
                    break;
                }
            }
        }

        return {
            items,
            total:items.length
        }
    }

    async getOne(type:RType, id:number|string): Promise<any>
    {
        const plural=getPluralName(type.Name);

        const item=await this.http.getAsync<any>(this.prefix+plural+'/'+id);

        return {
            data:item,
        }

    }

    async getMany(type:RType, ids:(number|string)[]): Promise<any[]>
    {
        const plural=getPluralName(type.Name);

        const list:any[]=[];
        for(const id of ids){
            const item=await this.http.getAsync<any>(this.prefix+plural+'/'+id);
            if(item){
                list.push(item);
            }
        }

        return list;
    }

    async update(type:RType, id:number|string, data:any): Promise<any>
    {

        const name=getNameFromPath(type.Name);
        const plural=getPluralName(name);

        await this.http.putAsync<any>(this.prefix+plural+'/'+id,data);

        const item=await this.http.getAsync<any>(this.prefix+plural+'/'+id);

        return item;
    }

    async create(type:RType,data:any): Promise<any>
    {
        const name=getNameFromPath(type.Name);
        const plural=getPluralName(name);

        const item=await this.http.postAsync<any>(this.prefix+plural,data);

        return item;
    }

    async delete(type:RType, id:number|string): Promise<void>
    {
        const plural=getPluralName(type.Name);
        await this.http.deleteAsync(this.prefix+plural+'/'+id);
    }
}

export function getPluralName(name:string){
    if(!name){
        return name;
    }
    const n=name.toLowerCase();
    if(n==='media' || n==='metadata'){
        return name;
    }
    if(n.endsWith("as")){
        return name+'es';
    }
    const c=n[n.length-1];
    switch(c){
        case 'y':
            return name.substr(0,name.length-1)+'ies';
        default:
            return name+'s';
    }
}

function getNameFromPath(path:string){
    if(!path){
        return path;
    }
    const parts=path.split('/');
    return parts[parts.length-1];
}

export const ReflectProviderContext=React.createContext<ReflectProvider|null>(null);

export function useReflect():ReflectProvider
{
    return useContext(ReflectProviderContext) as any;
}

export function createDefaultData(type:RType):any
{
    const data:any={}
    for(const prop of type.Props){
        let value:any;
        if(prop.Name==='Id'){
            value=0;
        }else if(prop.IsRefId || prop.IsRef || prop.IsCollectionRef){
            value=null;
        }else{
            switch(prop.Type){

                case RDataType.Bool:
                    value=false;
                    break;

                case RDataType.String:
                case RDataType.DateTime:
                    value='';
                    break;

                case RDataType.Int:
                case RDataType.Float:
                    value=0;
                    break;

                case RDataType.Object:
                    value=null;
                    break;
            }
        }
        data[prop.Name]=value;
    }
    return data;
}


export function getDisplayName(item:any,type?:RType){
    if(!item){
        return type?.Name||'(Item)';
    }

    if(item.Name){
        return item.Name;
    }

    if(item.FirstName && item.LastName){
        return item.FirstName+' '+item.LastName;
    }

    if(item.FirstName){
        return item.FirstName;
    }

    if(item.LastName){
        return item.FirstName;
    }

    if(item.Value){
        return item.Value;
    }

    if(item.Id){
        return '(Id:'+item.Id+')';
    }

    return type?.Name||'(Item)';
}

export function getDisplayValueForProp(prop:RProp, ctx:RCtx, item:any, maxLength?:number)
{
    if(!item){
        return '';
    }
    let display=item[prop.Name];
    if(display===null || display===undefined){
        return '';
    }
    if(display===true){
        display='✅';
    }else if(display===false){
        display='🔴';
    }else if(prop.IsEnum){
        const en=ctx.Enums.find(e=>e.FullName===prop.TypeFullName);
        if(en){
            if(en.IsFlags){
                let fd='';
                for(const ev of en.Values){
                    if(display&ev.Value){
                        fd+=(fd?',':'')+ev.Name;
                    }
                }
                if(!fd){
                    fd=en.Values.find(v=>!v.Value)?.Name||'(none)';
                }

            }else{
                display=en.Values.find(v=>v.Value===display)?.Name||display;
            }
        }
    }
    display=display+'';
    if(maxLength && display.length>maxLength){
        display=display.substr(0,maxLength)+'…';
    }
    return display;
}
