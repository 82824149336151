import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useApp, useAsync } from '../../lib/oc-admin-hooks';
import { createDefaultData, getDisplayName, ReflectProvider, SubmitHandler } from '../lib/reflect-admin';
import { RProp, RType } from '../lib/reflect-type';
import { inputForProp } from './AdminInputs';
import AdminPrompt from './AdminPrompt';
import AdminTrail from './AdminTrail';

interface AdminEditProps
{
    provider:ReflectProvider;
    type:RType;
    id:number|string;
    create?:boolean;
}

export default function AdminEdit({
    type,
    provider,
    id,
    create=false
}:AdminEditProps){

    const {nav}=useApp();

    const result=useAsync(
        null,
        async ()=>{
            if(create){
                return createDefaultData(type);
            }else{
                return await provider.getOne(type,id);
            }
        },
        'Unable to load item',
        [provider,type,id,create]
    )

    const data=useMemo(()=>result?{...result.data}:null,[result]);
    const [busy,setBusy]=useState(false);
    const busyRef=useRef(false);
    const [setSubmit,setShowSubmit]=useState(false);

    const beforeSubmit=useRef<SubmitHandler[]>([]);

    const table=useMemo(()=>{
        if(!data){
            return null;
        }

        const onChange=(prop:RProp,newValue:any)=>{
            if(busyRef.current){
                return;
            }
            setShowSubmit(true);
            data[prop.Name]=newValue;
        }

        const cells:any[]=[];

        if(!create){
            cells.push(<div className="admin-grid-label-cell" key={'label:-id'}>Id</div>);
            cells.push(<div key={'value:-id'}>{data.Id}</div>);
        }

        for(const prop of type.Props){

            const propConfig=provider.getPropConfig(prop,type);

            if(prop.Name==='Id' || (propConfig?.isReadonly && create)){
                continue;
            }

            const input=inputForProp({
                provider,
                prop,
                type,
                data,
                onChange,
                forCreate:create,
                beforeSubmit:beforeSubmit.current,
                propConfig,
                disabled:propConfig?.isReadonly?true:false
            });
            if(!input){
                continue;
            }

            cells.push(<div className="admin-grid-label-cell" key={'label:'+prop.Name}>{prop.Name}</div>);
            cells.push(<div key={'value:'+prop.Name}>{input}</div>);
        }

        return {cells};
    },[data,type,provider,create]);


    const onSubmit=useCallback(async (cancel:boolean,e:any)=>{
        e?.preventDefault?.();
        if(cancel){
            return;
        }
        setBusy(true);
        busyRef.current=true;
        try{

            const before=[...beforeSubmit.current];

            for(const b of before){
                await b(provider,type,data);
            }

            const result=create?
                await provider.create(type,data):
                await provider.update(type,data.Id,data);
            if(result){
                for(const e in data){
                    delete data[e];
                }
                for(const e in result){
                    data[e]=result[e];
                }
            }
        }catch(ex){
            console.error('Submit item failed',ex);
            return;
        }finally{
            busyRef.current=false;
            setBusy(false);
            setShowSubmit(false);
        }
        if(create){
            nav.push(`/db/${type.Name}/${data.Id}`);
        }
    },[data,provider,type,create,nav]);

    const [showDelete,setShowDelete]=useState(false);

    const deleteItem=useCallback(async ()=>{
        setShowDelete(false);
        if(!data){
            return;
        }

        setBusy(true);
        busyRef.current=true;
        try{
            await provider.delete(type,data.Id);
        }catch(ex){
            return;
        }finally{
            busyRef.current=false;
            setBusy(false);
            setShowSubmit(false);
        }
        nav.push(`/db/${type.Name}`);

    },[data,provider,nav,type]);

    return (
        <div className={'admin-screen admin-screen-edit'+(busy?' admin-screen-busy':'')}>
            <AdminTrail dashboard type={type} />
            <h3 className="admin-title">{create?'Create':'Edit'} {type.Name} {!create&&<b>{getDisplayName(data)}</b>}</h3>
            <form onSubmit={e=>onSubmit(busy||create?true:!setSubmit,e)}>

                <div className="admin-grid admin-grid-detail" style={{gridTemplateColumns:'min-content auto'}}>
                    {table?.cells}
                </div>
                <div className="admin-toolbar">
                    <div>
                        <button
                            disabled={!setShowSubmit}
                            type={create?'button':'submit'}
                            className="admin-button"
                            onClick={create?()=>onSubmit(busy,null):undefined}>{create?'Create':'Save'}</button>
                    </div>
                    <div>
                        {!create&&<button type="button" className="admin-button" onClick={()=>setShowDelete(true)}>Delete</button>}
                    </div>
                </div>
            </form>
            <AdminPrompt
                title={<>Delete <b>{getDisplayName(data)}</b>?</>}
                show={showDelete}
                closeRequested={setShowDelete}>

                <button className="admin-button" onClick={deleteItem}>Yes</button>
                <button className="admin-button" onClick={()=>setShowDelete(false)}>No</button>

            </AdminPrompt>
        </div>
    )

}
